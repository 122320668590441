<template>
    <div>
        <main-header></main-header>
        <breadcrumbs></breadcrumbs>

        <div class="wrapper">

            <div class="content terms">
                <div class="content__heading">
                    <h1>Terms & Conditions of Use of Terrific.ie</h1>
                    <small>Last Updated 11 September 2020</small>
                </div>
                <h2>1. Introduction</h2>
                <p>Welcome to the Terrific.ie website. This document sets out the Terms of Use of our website, and
                    applies to anyone who accesses or uses our site. This includes pages consisting of or containing
                    Terrific.ie. These Terms of Use apply to usage of all services provided by us, including access to
                    our site via desktop, as well as mobile and app usage. The "Website", "Site", or "Terrific.ie" and
                    the provision of our advertising services on the Website are subject to you accepting the Terms of
                    Use (defined below).</p>
                <p>The agreement between you and us in relation to your use of the Website (the "User Agreement")
                    consists of these terms and conditions of use ("Terms of Use"), the policies referred to in clause
                    11 below (each a "Policy" and together the "Policies"), all of which you should read carefully
                    before you start to use the Website.</p>
                <h2>2. Your Consent</h2>

                <p>Please review the Terms of Use and each Policy carefully. By accessing and using the Website, you
                    confirm that you have read, understand and accept the Terms of Use and the Policies and that you
                    agree to abide by their respective terms each time you access and use the Website. If you do not
                    agree with any of the Terms or Use or any Policy, you may not use the Website.</p>

                <p>Registered users of our site will be required to agree to these Terms of Use in order to carry out
                    core services provided by the Site.</p>

                <h2>3. Modification of Terms</h2>

                <p>We reserve the right in our sole discretion to change and update the Terms of Use and any Policy at
                    any time and recommend that you revisit this page regularly to keep informed of the current Terms of
                    Use and Policies that apply to your use of the Website. Any such changes will take effect
                    immediately when posted on the Website.</p>
                <p>Registered users of the site will be alerted to any change of the Terms of Use, and will be required
                    to confirm your agreement to the updated Terms in order to continue to carry out core services
                    provided by the Site.</p>

                <p>By continuing to access, browse and use the Website, unregistered users will be deemed to have agreed
                    to any changes or updates to the Terms Use and any Policy.</p>

                <p>We reserve the right to change the Terms of Use and any Policy at any time and to notify you by
                    posting an updated version of the Terms of Use and/or the applicable Policy on the Website. Please
                    re-read the Terms of Use and the Policies from time to time as your continued use of the Website
                    means you have read, understood and agreed to be bound by the then prevailing Terms of Use and
                    Policies.</p>

                <h2>4. Access to and use of the Website and the Terrific.ie Service</h2>

                <p><i>Age and responsibility</i>The use of the Website and/or the Terrific.ie Service by a minor (an
                    individual under 18 years of age) is subject to the consent of their parent or guardian and our
                    contract is with the parent or guardian, who is responsible for the minor's compliance. We advise
                    parents or guardians who permit minors to use the Website and/or the Terrific.ie Service that it is
                    important that they communicate with minors about their safety online, as moderation or vetting of
                    advertisements is not guaranteed or fool proof. Minors who are using any interactive service should
                    be made aware of the potential risks to them.</p>
                <p>You are responsible for making all arrangements necessary for you to have access to the Website
                    and/or the Terrific.ie Service. You are also responsible for ensuring that all persons who access
                    the Website and/or the Terrific.ie Service through your internet connection, including any minors,
                    are aware of these terms, and that they comply with them.</p>
                <p>The services which are made available on the Website by us from time to time will, amongst other
                    things, allow you: (i) to create, publish and manage written advertisements for vehicles for sale;
                    (ii) to create, publish and manage written advertisements; (iii) to contact other users of the
                    Website; (iv) to purchase a vehicle history check; (v) to search our database for advertisements
                    regarding a specific vehicle in a specific location; (vi) to forward advertisements to people you
                    know; (vii) to invite people you know to view the Website; (viii) to download or copy portions of
                    information, data, text, sound, images, photographs, graphics, video, messages and other materials;
                    and (ix) to view third party material and receive short service messages (SMS) as part of the SMS
                    Service (defined below) and other items displayed on the Website ((i) to (ix), together, "Content")
                    (the "Terrific.ie Service").</p>
                <p>You may use the Website for your own personal and commercial or non-commercial use to avail of the
                    Terrific.ie Service strictly in accordance with the User Agreement.</p>
                <p>The User Agreement applies all users and visitors to the Website and the Terrific.ie Service.</p>
                <h2>5. Registration of accounts, usernames and passwords, deactivating accounts</h2>
                <p>Registration is not required if you simply want to browse through the advertisements on the Website.
                    Registration is required if you want access core services, including but not limited to, placing
                    advertisements, saving searches, and interacting with other users.</p>
                <p>As part of the registration process, you will be asked to enter your email address and a password and
                    you will be responsible for all activities occurring under your account and for keeping your
                    password secure. This includes a responsibility to ensure that you do not enter your account
                    information into a phishing website, or any other website purporting to be or offer services for
                    Terrific.ie. All information supplied must be complete and accurate.</p>
                <h3>Names and Email Addresses</h3>
                <p>You may not enter a name or email address that:</p>
                <ul>
                    <li>Contains "Terrific" or otherwise misrepresents your relationship with Terrific.ie or any other
                        party;
                    </li>
                    <li>Contains any profanity, is vulgar or offensive, or promotes an illegal activity;</li>
                    <li>Violates any trade mark or other proprietary right; and/or</li>
                    <li>Misleadingly impersonates someone else.</li>
                </ul>
                <p>We reserve the right to deactivate accounts containing names and/or email addresses that do not
                    comply with these Terms of Use at our discretion.</p>
                <h3>Accounts and passwords</h3>
                <p>We reserve the right to log off or deactivate accounts that are inactive for an extended period of
                    time.</p>
                <p>You are responsible for all actions taken under your account and you agree only to use or utilise the
                    Website using your own name, email address and password. You must make reasonable efforts to keep
                    your password safe and not disclose it to any other person or permit either directly or indirectly
                    any other person to utilise your email address or password. We reserve the right to terminate your
                    registration without prior notice at our discretion.</p>
                <p>You agree to notify us immediately if you suspect any unauthorised use of your account or access to
                    your password.</p>
                <p>You may change your password or profile by following instructions on the Website.</p>
                <p>We will not be liable for any loss that you may incur as a result of someone else using or accessing
                    your password or account, either with or without your knowledge.</p>
                <p>You may be held liable for losses incurred by us or any other party as a result of someone else using
                    or accessing your password or account if you have not taken reasonable steps to keep your password
                    or account secure.</p>
                <p>By providing us with your email address, you consent to our using that email address to send you
                    Terrific.ie service related notices, including any notices required by law, in lieu of communication
                    by post. This will include notifications about leaving feedback on completion of a transaction as
                    feedback left between people is an integral part of the Website; this specific notification is not
                    available for opting out. We may also use your email address to send you other messages, such as
                    changes to features of the Terrific.ie Service and special offers. If you do not want to receive
                    such email messages, you may opt out or change your preferences in your account settings. Opting out
                    may prevent you from receiving email messages regarding updates, improvements or offers.</p>
                <p>We do not sell or knowingly pass any personal information from your account to any third party,
                    except information which is required to provide the service between buyers and sellers. Your details
                    and privacy are important to us. If you feel that your information has been compromised in any way,
                    please report it immediately to us at sup<span class="spamprotection"></span>port@terr<span class="spamprotection"></span>ific.<span class="spamprotection"></span>ie</p>
                <p>Further information about how we use your information, and your rights to update your communication
                    preferences, can be found within our Privacy Policy.</p>
                <h3>Deactivating accounts</h3>
                <p>In accordance with Data Protection Law, non- registered and registered users of our sites can request
                    we delete any personally identifiable information. In some instances we may be unable to delete your
                    data if we are required to retain it to comply with our legal obligations (including requests from
                    law enforcement agencies).</p>
                <p>You can learn more about your right to data deletion and how long we retain data for by reading our
                    Privacy Policy.</p>
                <h2>6. Using and accessing the Website, Content and the Terrific.ie Service</h2>
                <p>We have the right to temporarily or permanently disable, suspend or restrict without limitation any
                    password, name, mobile phone number, IP address or other method that we may allow to be used on the
                    Website from time to time to identify users (whether chosen by you or allocated by us) (a "User
                    Identifier"), at any time, at our discretion, including if, in our opinion, you have failed to
                    comply with any of the provisions of the Terms of Use or any applicable Policy.</p>
                <p>You may not: (i) enter, select, submit or use a false name or an email address owned or controlled by
                    another person with the intent to impersonate that person; (ii) use a User Identifier without
                    appropriate authorisations or rights; or (iii) use or submit a mobile phone number or email to us in
                    respect of which you are not the registered owner, bill-payer and or account holder. We reserve the
                    right in our sole discretion to refuse registration of, suspend or cancel a User Identifier's use or
                    access rights to the Website without notice. You shall be responsible for maintaining the
                    confidentiality of your User Identifier(s) and are fully responsible for all activities that occur
                    under your User Identifier in connection with your use of the Website or the Terrific.ie Service.
                    Any User Identifiers created by you or submitted for your access to the Terrific.ie Service and
                    Website shall be for your personal use only and are non-transferable.</p>
                <p>You must immediately notify us of any unauthorised use of your User Identifier as registered on the
                    Website or submitted to us by you, or any other related breach of security. We will not be liable
                    for any loss or damage arising from your failure to comply with this provision.</p>
                <p>If you forget or lose your User Identifier details used for the purposes of accessing restricted
                    areas of the Website, you should request these from us by visiting the 'Forgotten' section of the
                    Website.</p>
                <p>Only users in Ireland (including Northern Ireland) may advertise on the Website and the Terrific.ie
                    Service. This is without prejudice to the rights of users outside of Ireland to use the Website for
                    the purposes of searching our database of advertisements. You may not use or access the Website in
                    and from jurisdictions in which it or its contents are restricted or prohibited by local law or
                    otherwise.</p>
                <p>As a condition of your access and use of the Website and/or the Terrific.ie Service, you warrant to
                    us that you have the right, authority and capacity to enter into and be bound by the Terms of
                    Use.</p>
                <p>We have no obligation to monitor or moderate any user's activity or use of the Website and/or the
                    Terrific.ie Service, however we retain the right at all times to monitor, retain and disclose any
                    information as necessary to satisfy any applicable law, regulation, legal process or regulatory
                    authority request or to determine compliance with the Terms of Use or otherwise.</p>
                <p>You agree and acknowledge that Terrific.ie is an information society service provider, as defined in
                    Directive 2000/31/EC and transposed into Irish law by the European Communities (Directive 2000/31/
                    EC) Regulations, 2003 (S.I. 68 of 2003), and that it is not under any obligations to monitor the
                    information which it transmits or stores on the Sites. You agree and acknowledge that Terrific.ie
                    shall not be liable in respect of information which is hosted or cached by it or in respect of which
                    it acts as a mere conduit. Terrific.ie operates a "notice and take down" policy.</p>
                <p>Without any admission of liability, we may from time to time assess any possible risks for users from
                    third parties when they use the Website and/or the Terrific.ie Service, and we will decide in each
                    case whether it is appropriate to use moderation or pre-approval of advertisements on the Website
                    (including what kind of moderation to use) in the light of those risks. We expressly exclude our
                    liability (whether in contract, tort or otherwise) for any loss or damage arising out of or in
                    connection with the use of the Website and/or the Terrific.ie Service by a user in contravention of
                    the Terms of Use, whether the service is moderated or not and whether or not an advertisement has
                    been reviewed by us prior to appearing on the Website.</p>
                <p>You understand that, except for information, products or services clearly identified as being
                    supplied by us, we do not operate, control or endorse any information, products or services on the
                    Internet in any way. Except where identified otherwise, all information, products and services
                    offered through the Website or on the Internet generally are offered by third parties that are not
                    affiliated with us. You also understand that we do not guarantee or warrant that files available for
                    downloading through the Website will be free of infection or viruses, worms, Trojan horses or other
                    code that manifest contaminating or destructive properties. You are responsible for implementing
                    sufficient procedures and checkpoints to satisfy your particular requirements for accuracy of data
                    input and output, and for maintaining a means external to the Website for the reconstruction of any
                    lost data.</p>
                <p>You should not rely on any content and/or any other material on the Website to make or refrain from
                    making any decision or take or refrain from taking any action on which reliance should be placed. We
                    disclaim all liability and responsibility arising from any reliance placed on such content and/or
                    materials by any user of the Website, or by anyone who may be informed of any of the content. We
                    accept no responsibility for keeping the information on the Website up to date or complete, nor do
                    we accept any liability for any failure to do so. It is solely your responsibility to evaluate the
                    accuracy, completeness and usefulness of all opinions, advice, services, merchandise and other
                    information provided through the service or on the internet generally.</p>
                <p>We reserve the right to refuse to publish any content or other material or to provide any other
                    services without prior notice to any user or users for any reason or no reason.</p>
                <p>We do encourage the use by you of the 'Report' mechanism which we have placed on the Website which
                    allows you to report any Content that is in breach of the Terms of Use or any applicable Policy or
                    is in any other way objectionable.</p>
                <p>Terrific.ie will use all reasonable endeavors to remove the offending content complained about within
                    a reasonable period of time. Any content reported to us will be reviewed from 9am to 5pm, Monday to
                    Friday, excluding national and public holidays in the Republic of Ireland. Any notification received
                    outside of the aforementioned hours will only be addressed upon the reopening of Terrific.ie
                    offices.</p>
                <p>Access to the Website and/or the Terrific.ie Service is permitted on a temporary basis, and we
                    reserve the right to at any time and without liability to withdraw, suspend or amend any aspect or
                    feature of the Website and/or the Terrific.ie Service without notice or liability.</p>
                <h2>7. Restrictions on right to use</h2>
                <p>You must not (and you must not to allow any third party to):</p>
                <p>a. modify, adapt, translate, or reverse engineer any portion of the Website and/or the Terrific.ie
                    Service;</p>
                <p>b. remove any copyright, trade mark or other proprietary rights notices contained in or on the
                    Website and/or the Terrific.ie Service or in or on any Content or other material obtained via the
                    Website and/or the Terrific.ie Service;</p>
                <p>c. use any robot, spider, website search/retrieval application, or other automated device, process or
                    means to access, retrieve or index any portion of the Website and/or the Terrific.ie Service;</p>
                <p>d. access, retrieve or index any portion of the Website and/or the Terrific.ie Service for purposes
                    of constructing or populating any database;</p>
                <p>e. collect any information about other users or users of the Website and/or the Terrific.ie Service
                    (including User Identifiers) for any purpose other than to use the Website for the purposes of
                    responding to advertisements or to use the Website in the manner intended by us;</p>
                <p>f. create or transmit unwanted electronic communications such as 'spam' to other users of the Website
                    and/or the Terrific.ie Service or otherwise interfere with other user's enjoyment of the Website
                    and/or the Terrific.ie Service;</p>
                <p>g. collect or store personal data about other users in connection with the prohibited activities
                    described in this section of the Terms of Use;</p>
                <p>h. engage in or promote any of the following (or what we deem similar): surveys, contests, pyramid
                    schemes, chain letters, get rich quick promotions, profit sharing promotions, unsolicited emailing
                    or spamming via the Website;</p>
                <p>i. impersonate any person or entity;</p>
                <p>j. identify or speculate as to the identity of any anonymous or pseudonymous user; or</p>
                <p>k. solicit passwords or personally identifying information for commercial or unlawful purposes.</p>
                <p>l. reformat or frame any portion of the web pages that are part of the Website and/or the Terrific.ie
                    Service;</p>
                <p>m. create user accounts by automated means or under false or fraudulent pretences or create multiple
                    log ins;</p>
                <p>n. transmit any viruses, worms, defects, Trojan horses or other items of a destructive nature;</p>
                <p>o. use the Website or the Terrific.ie Service to violate the security of any computer network, crack
                    passwords or security encryption codes, transfer or store illegal material including that are deemed
                    threatening or obscene;</p>
                <p>p. copy or store any Content offered on the Website for other than your own use;</p>
                <p>q. use any device, software or routine that interferes with the proper working of the Website and/or
                    the Terrific.ie Service, or otherwise attempt to interfere with the proper working of the Website
                    and/or the Terrific.ie Service;</p>
                <p>r. take any action that imposes, or may impose in our sole discretion, an unreasonable or
                    disproportionately large load on our IT infrastructure;</p>
                <p>s. use the Website and/or the Terrific.ie Service, intentionally or unintentionally, to violate any
                    applicable law;</p>
                <p>t. upload, post, email, transmit or otherwise make available using the Website any material that you
                    do not have a right to make available under any law or contractual obligation which includes a
                    breach of third party intellectual property rights;</p>
                <p>u. attempt to gain unauthorised access to the Website, its facilities and/or services or any
                    accounts, computer systems and networks connected to any Terrific.ie website, its facilities and/or
                    services through hacking, password mining or any other means;</p>
                <p>v. post or use any material that is obscene, defamatory, seditious, indecent, offensive, incite
                    racial hatred or incite a crime, be naming or scandalous or inappropriate; and/or</p>
                <p>w. harvest or otherwise collect by any means any programme material or information from the Website
                    unless authorised under the Terms of Use or to monitor, mirror or copy any content of the Website
                    without our prior written consent.</p>
                <p>We reserve the absolute right at our sole discretion, without being obliged to give any reason, to
                    reject, amend or remove any Content posted or submitted by you at any time and without notice to
                    you. We reserve the right to monitor and to review all Content submitted to us, accessed on or
                    published on the Website at any time.</p>
                <h2>8. Your interactions with other users</h2>
                <p>We have no obligation to verify the identity of any users when they are connected to the Website or
                    to monitor material provided by them.</p>
                <p>As a result of the variation in community standards and individuals sometimes choosing not to comply
                    with our policies and guidelines, in the process of using the Website, you may be exposed to content
                    that you find offensive or objectionable. You can report such content to us using the "Report"
                    mechanism.</p>
                <p>Your interactions with other users, including payment and delivery of goods or services, and any
                    other terms, conditions, warranties or representations associated with such dealings, are solely
                    between you and the other user.</p>
                <p>We accept no responsibility for transactions that take place on the Website. While we reserve the
                    right to monitor or to intervene in disputes between you and other users, we have no obligation to
                    do so.</p>
                <p>As with any web-based interaction, we suggest that you use caution and good judgment. If there is a
                    dispute between you and any third party (including any advertiser), we are under no obligation to
                    become involved.</p>
                <p>You are encouraged to exercise discretion when providing personal information about yourself on the
                    Sites. Any personal information which you volunteer in your public profile will be available
                    worldwide to anyone with access to the website. Please note that certain information which you may
                    choose to provide might reveal data consisting of your racial or ethnic origin, political opinions,
                    religious or philosophical beliefs, or trade union membership, genetic data, biometric data, data
                    concerning health or data concerning your sex life or sexual orientation.</p>
                <p>When users are involved in a transaction, information such as each other's name, username, email
                    address, and other contact information, postal information and financial information may be
                    exchanged between the users. We cannot guarantee that other users will respect the privacy or
                    security of your information and therefore we encourage you to evaluate the privacy and security
                    policies of your trading partners before entering into transactions and choosing to share your
                    information with them. Similarly, we ask you to respect other users' privacy and disclose your
                    privacy and security policies to them. In all cases, you must comply with data protection laws and
                    give other users a chance to remove themselves from your database and a chance to review the
                    information you have collected about them.</p>
                <p>You may use other users' information only in accordance with applicable laws and regulations
                    (including, without limitation, data protection laws) and only for:</p>
                <p>a. Terrific.ie transaction related purposes that are not unsolicited commercial communications;</p>
                <p>b. using services offered through Terrific.ie; and</p>
                <p>c. other purposes that a user expressly agrees to.</p>
                <p>Although we cannot monitor the conduct of its users off-line, you must not use any information
                    obtained from the Website in order to harass, abuse or harm another person, or in order to contact,
                    advertise to, solicit or sell to any user or person without their prior expressive consent.</p>
                <p>We will not disclose any contact details in respect of advertisements that have been deleted or
                    removed in accordance with our obligations under applicable data protection laws. You can view our
                    <a href="/privacy">Privacy Policy here.</a></p>
                <h2>9. Content standards</h2>
                <p>These provisions apply to any and all Content which you contribute to the Website and/or the
                    Terrific.ie Service, and to any interactive services associated with it.</p>
                <p>You must comply with the spirit of the following standards as well as the letter. The standards apply
                    to each part of any Content as well as to its whole.</p>
                <p>You are solely responsible for any Content that you submit, publish or display on the Website or
                    transmit to other Website users.</p>
                <p>You are solely responsible for your advertisements listed on the Website. You understand that all
                    information publicly posted or privately transmitted through the Website is the sole responsibility
                    of the person from which such content originated and that we will not be liable for any errors or
                    omissions in any content or as a result of any user's use of the Website. You understand that we
                    cannot guarantee the identity of any other users with whom you may interact in the course of using
                    the Terrific.ie Service. Additionally, we cannot guarantee the authenticity of any data which users
                    may provide about themselves or relationships they may describe.</p>
                <p>By posting Content on the Website, you grant to us, and you represent and warrant that you have the
                    right to grant, us, our contractors, and the users of the Website an irrevocable, perpetual,
                    royalty-free, fully sub-licensable, fully paid up, worldwide license to use, copy, publicly perform,
                    digitally perform, publicly display, and distribute such Content and to prepare derivative works of,
                    or incorporate into other works, such Content. This licence is non-exclusive, except you agree that
                    we shall have the exclusive right to exercise this license to the extent of combining your Content
                    with the Content of other users for purposes of constructing or populating a searchable database of
                    advertisements. You understand that this licence allows us to reformat, excerpt, or translate any
                    materials submitted by you.</p>
                <p>The following is a non-exhaustive list of the criteria that Content must comply with. Content
                    must:</p>
                <p>a. be accurate (where it states facts);</p>
                <p>b. be genuinely held (where it states opinions); and</p>
                <p>c. comply with applicable law in Ireland, Northern Ireland and in any country from which it is
                    posted.</p>
                <p>The following is a non-exhaustive list of the type of Content that is prohibited on the Website. You
                    must not:</p>
                <p>a. comment on, defame, abuse, harass, stalk, threaten or otherwise offend others;</p>
                <p>b. post Content that is unlawful, obscene, defamatory, seditious, indecent, offensive, liable to
                    incite racial hatred, discriminatory, menacing, scandalous, inflammatory, be in breach of
                    confidence, be in breach of privacy or be inappropriate;</p>
                <p>c. post Content in breach any applicable laws or regulations;</p>
                <p>d. deceive or mislead any person and/or entity;</p>
                <p>e. falsely state, impersonate, or otherwise misrepresent your identity, including but not limited to
                    the use of a pseudonym, or misrepresenting your current or previous positions and qualifications, or
                    your affiliations with a person or entity, past or present;</p>
                <p>f. upload Content that duplicates Content already published on the Website;</p>
                <p>g. upload, post, email, transmit or otherwise make available any content or initiate communications
                    which include information that you do not have the right to disclose or make available under any law
                    or under contractual or fiduciary relationships (such as insider information, or proprietary and
                    confidential information learned or disclosed as part of employment relationships or under
                    nondisclosure agreements); upload, post, email, transmit or otherwise make available any content or
                    initiate communication that infringes upon patents, trade marks, trade secrets, copyrights or other
                    proprietary rights;</p>
                <p>h. upload, post, email, transmit or otherwise make available any unsolicited or unauthorised
                    advertising, promotional materials, 'junk mail', 'spam', 'chain letters', 'pyramid schemes', or any
                    other form of solicitation;</p>
                <p>i. upload, post, email, transmit or otherwise make available any material that contains software
                    viruses or any other computer code, files or programs designed to interrupt, destroy or limit the
                    functionality of any computer software or hardware or telecommunications equipment; and/or</p>
                <p>j. interfere with or disrupt the Website or servers or networks connected to the Website, or disobey
                    any requirements, procedures, policies or regulations of networks connected to the Website.</p>
                <p>We do not control the Content posted by users via the Terrific.ie Service, including the content of
                    any advertisements or blog postings, and we do not guarantee the accuracy, integrity or quality of
                    such Content. You understand that by using the Website and/or the Terrific.ie Service, you may be
                    exposed to Content that is offensive, indecent or objectionable. You are responsible for complying
                    with all laws applicable to the Content you submit to the Website via the Terrific.ie Service. We
                    may refuse any Terrific.ie Service without prior notice to any user for any or no reason. We also
                    have the right, at our discretion, to disclose your identity to any third party who is claiming that
                    any Content posted or uploaded by you to the Website constitutes a violation of their intellectual
                    property rights, or of their right to privacy.</p>
                <h2>10. Suspension and termination</h2>
                <p>We may, with or without cause, immediately delete your advertisement and terminate your access to the
                    Terrific.ie Service or a User Identifier without prior notice at our sole discretion. The deletion
                    or otherwise of an advertisement should not be viewed as an indication of wrongdoing on the part of
                    the advertiser.</p>
                <p>Without limiting the foregoing, the following may lead to a deletion by us of a user's advertisement
                    and/or termination of access to the Terrific.ie Service: (a) a breach of the User Agreement (b) a
                    request by law enforcement, An Garda Síochána / PSNI, police or other government agencies about you
                    or any Content that you have submitted to us; (c) a request by you (self-initiated deletion(s)); (d)
                    unexpected technical issues or problems; and (e) extended periods of inactivity. Deletion of a user
                    advertisement and termination of access to the Terrific.ie Service may result in the removal of all
                    Content that we may decide to delete. It may also result in preventing your further use of the
                    Website or a User Identifier, depending on the circumstances. Furthermore, you agree that
                    termination of the user Agreement or your use of the Website by us shall be made in our sole
                    discretion and that we shall not be liable to you nor any third party for any termination of your
                    account or access to the Website or removal of Content. You can view further information about how
                    we process your data by reading our Privacy Policy.</p>
                <p>We may review and delete any advertisements, blog postings or other Content that in our sole judgment
                    breach the User Agreement or which might be offensive, illegal or that might violate the rights of,
                    harm, or threaten the safety of other users of the Website or us.</p>
                <p>We reserve the right to investigate and we will determine, in our discretion, whether there has been
                    a breach of the User Agreement through your use of the Website and/or the Terrific.ie Service. When
                    a breach of the User Agreement has occurred, we may take such action as we deem appropriate.</p>
                <h2>11. Your concerns and complaints</h2>
                <p>If you have any concerns about material which appears on the Website or if you believe that Content
                    posted on the Website breaches the User Agreement or is in any other way objectionable, please
                    notify us through the 'Report' mechanism on the Website specifying the full details of your concern
                    or complaint, and specifying the reasons for your concern or complaint making clear reference to any
                    connected content.</p>
                <p>If you do not provide us with sufficient information, we may be unable to process your complaint. We
                    reserve the right to investigate and we will determine, in our discretion, what action (if any) to
                    take. You are solely responsible for your interactions with other users of the Website. While we
                    reserve the right to monitor or to intervene in disputes between you and other users, we have no
                    obligation to do so.</p>
                <p>We will use all reasonable endeavours to respond to complaints received as soon as possible, and our
                    aim is to respond to all complaints within one working day. However, please note that from time to
                    time it may take longer than one working day to deal with complaints.</p>
                <h2>12. Disputes with third parties</h2>
                <p>You must tell us immediately if anyone makes or threatens to make any claim or issue legal
                    proceedings against you relating to your use of the Website and/or the Terrific.ie Service. You
                    must, at our request, immediately stop the act complained of. If we ask you to, you must confirm the
                    details of the claim in writing. If you fail to stop the act or acts complained of we reserve the
                    right to take any and all appropriate action against you.</p>
                <h2>13. Payment for advertisements</h2>
                <p>Publication of an advertisement is subject to payment of a fee to Terrific.ie (the "Fee"). The terms
                    of the Fee are set out on the Website and displayed to you in the course of you offering to place an
                    advertisement with us. The Fee for all Terrific.ie Services is quoted euro and is payable in euro.
                    All prices are stated inclusive of valued added tax (VAT) at the relevant rate.</p>
                <h2>14. Cancellation policy</h2>
                <p>Once your advertisement is posted on the Website your request for our services cannot be cancelled at
                    that stage. Prior to the advertisement appearing on the Website you may cancel the advertisement by
                    contacting us.</p>
                <h2>15. Published advertisements and duration of advertisements</h2>
                <p>Once you have completed the process of submitting your advertisement we will send you an email
                    confirming receipt of your order for the Terrific.ie Service, this email does not constitute an
                    acceptance of your order. Your order for posting an advertisement on the Website will not be
                    accepted and an agreement concluded between us and you in relation to the publication of the
                    advertisement on the Website until the payment for the Terrific.ie Service has been received by
                    us.</p>
                <p>Advertisements will be displayed for the period stated on the relevant ad entry page for each ad
                    type. If you wish to extend this period you will be required to resubmit your request for the
                    Terrific.ie Service and to make a further payment for those services.</p>
                <p>Content which comprises advertising material will appear on the Website. Individual advertisers are
                    solely responsible for the content of the material which they submit to us including ensuring that
                    it complies with relevant legislation. We accept no responsibility for the content of any business
                    and/or advertising material, including, without limitation, any error, omission or inaccuracy.</p>
                <p>Please note that the content of the published advertisements is provided by external users, and do
                    not represent any views or opinions of Terrific.ie, nor should they purport to do so.</p>
                <p>Accordingly, we require that every care is taken by you or on your behalf in drafting the content of
                    your advertisements in order to ensure that they are not in any way defamatory, illegal or in any
                    other way harmful to a third party as you are solely responsible as author and publisher of your
                    advertisement.</p>
                <h2>16. Advertising terms and conditions</h2>
                <p>When creating an advertisement on the Website you must provide accurate, current and complete
                    information and to promptly update the information that you provide to us when it changes.</p>
                <p>Advertisements containing illegal, defamatory, obscene, indecent or otherwise offending material are
                    not allowed on the Website. Duplicate advertisements are also prohibited.</p>
                <p>We do not control the content of advertisements posted by users on the Website, and we do not
                    guarantee the accuracy, integrity or quality of the content. You understand that by using the
                    Website, you may be exposed to content that is offensive, indecent or objectionable without
                    liability.</p>
                <p>Terrific.ie is a family website so we reserve the right to review and reject any advertisements that
                    we feel have an adult theme. In particular no graphic images may be used in advertisements, any
                    inappropriate content will be removed.</p>
                <p>Advertisements which are posted and are found to be in breach of our policies will be removed without
                    prior notification and no refund will be given. Users who are found to be in violation of our
                    policies on more than one occasion may have their account removed and their information passed to
                    the policing authority.</p>
                <h2>17. Commercial Advertising</h2>
                <p>Businesses and persons acting in the course of trade are welcome to place classified advertisements
                    on the Website.</p>
                <p>Advertisements published by businesses or persons acting in the course of trade must comply with
                    applicable laws and it is those persons' responsibility to do so. We will hold you responsible for
                    any failure to comply with laws that apply to you or your content. You may need to adhere to the
                    Consumer Protection Act 2007, the European Communities (Misleading and Comparative Marketing
                    Communications) Regulations 2007, the European Communities (Misleading Advertising) Regulations
                    1988, European Communities (Protection of Consumers in Respect of Contracts made by means of
                    Distance Communications) Regulations 2001, the European Communities (Directive 2001/31/EC)
                    Regulations, 2003 (each as amended, supplemented or replaced from time to time) (the "Ecommerce
                    Regulations") and any other consumer protection legislation at the time being in force. Please note
                    that the Website is aimed at consumers in Ireland (including Northern Ireland), but we cannot
                    prevent you being contacted from other consumers or other third parties nor can we prevent your
                    advertisement being displayed in other jurisdictions.</p>
                <h2>18. Fair usage policy</h2>
                <p>To ensure the best browsing experience for our users, your potential buyers, we ask that you only
                    create one advertisement per vehicle.</p>
                <p>We only allow one vehicle per advertisement. This ensures that your vehicle will appear in the
                    correct search and helps them sell quicker. Your advertisement may be removed if:</p>
                <p>a. you have more than one vehicle in the listing;</p>
                <p>b. you have multiple listings for one vehicle across several sections; and/or</p>
                <p>c. you have multiple listings for one vehicle across several counties.</p>
                <p>This will make it easier for a potential buyer to find your vehicle. It will also make it easier for
                    the advertisement to be removed when the vehicle is no longer available. If this policy is not
                    complied with, we reserve the right to remove the offending advertisement(s) from the Website
                    without notice to you and without refund.</p>
                <h2>19. Reporting breaches</h2>
                <p>Please use the 'Report' mechanism which we have placed on the Website to report any advertisement
                    that is in breach of the User Agreement, illegal or in any other way objectionable.</p>
                <h2>20. Standards</h2>
                <p>All advertisements must adhere to the 'Code of Advertising Standards' of The Advertising Standards
                    Authority for Ireland in addition to the standards set out in the Agreement.</p>
                <h2>21. Responsibility</h2>
                <p>You are solely responsible for the Content appearing in any of your advertisements and for the
                    vehicle sold following advertisement. We are not responsible for the vehicles appearing on the
                    Website that you advertise or purchase, nor are we responsible for the Content appearing in any of
                    the advertisements. To the fullest extent permitted by law we disclaim any and all liability in
                    respect of any claims arising from any reliance placed on the Content of any advertisement by any
                    user of the Website or any claims arising in respect of any goods to which the Content relates.</p>
                <h2>22. Consequences for failure to comply with the User Agreement</h2>
                <p>Failure by you to comply with the User Agreement may result in our taking all or any of the following
                    actions:</p>
                <p>a. immediate, temporary or permanent withdrawal of your right to use the Website and/or the
                    Terrific.ie Service;</p>
                <p>b. immediate, temporary or permanent removal of any posting, material or Content uploaded by you to
                    the Website;</p>
                <p>c. issue of a public (via the Website) or private warning to you;</p>
                <p>d. limit the amount of postings you may make;</p>
                <p>e. suspension, restriction or limitation of any rights or permissions associated with a User
                    Identifier;</p>
                <p>f. initiation of legal proceedings against you for reimbursement of all costs on a full indemnity
                    basis (including, but not limited to, reasonable administrative and legal costs) resulting from the
                    breach;</p>
                <p>g. other legal action against you; and/or</p>
                <p>h. disclosure of such information to law enforcement authorities as we reasonably feel is necessary,
                    as set out in our Privacy Policy.</p>
                <p>
                    We exclude liability for actions taken in response to breaches of the Terms of Use. The responses
                    described above are not exhaustive and we may take any other action we reasonably deem
                    appropriate.</p>
                <h2>23. Banner advertising</h2>
                <p>Banner advertising on the Website is managed and provided by third party service provider(s)
                    whom/which we allow to provide services using the Website and who/which we will identify on the
                    Website from time to time (each a "Banner Advertiser"). To place banner advertising on the Website,
                    please contact the Banner Advertisers using the contact details identified on the Website from time
                    to time. Please note that any agreement that you reach with any Banner Advertiser in relation to a
                    banner or other advertising service on the Website is with that Banner Advertiser and it is subject
                    to you entering into an agreement with that Banner Advertiser for that advertisement service. We are
                    not responsible for any Banner Advertiser's services to you notwithstanding that the advertisement
                    will appear on the Website. Please read the privacy policy and terms and conditions that apply to
                    any Banner Advertiser's services carefully before placing a banner advertisement or availing of
                    another third party advertising service related to or available on the Website.</p>
                <h2>24. Refund policy</h2>
                <p>Where advertisements or other Content are rejected and/or removed from the Website by us, it is our
                    policy NOT to issue refunds. If you have any doubts about the suitability of a proposed
                    advertisement, please contact us in advance of placing the advertisement for appropriate
                    guidance.</p>
                <h2>25. Intellectual property</h2>
                <p>The Website and the Terrific.ie Service (including, but not limited to, text, images, photographs,
                    graphics, video and audio Content) are protected by copyright as works, databases, collective works
                    and/or compilations under the copyright laws of Ireland, Northern Ireland and other countries. All
                    individual advertisements, articles, Content and other elements comprising the Website and the
                    Terrific.ie Service are also copyrighted works. Except for the Content that you submit ("User
                    Content"), all intellectual property rights (including copyright, design rights and trade marks) in
                    the Website, Content, User Identifiers and the Terrific.ie Service ("Protected IP") are as between
                    you and us are owned by us or our licensors. You acknowledge that the Website, User Identifiers and
                    the Terrific.ie Service and any underlying technology or software used in connection with the
                    Website and the Terrific.ie Service contains our proprietary information. You may not modify,
                    reproduce, distribute, create derivative works of, publicly display or in any way exploit, any of
                    the content, software, and/or materials available on the Website, or the Terrific.ie Service in
                    whole or in part except as expressly provided. Except as expressly and unambiguously provided in
                    these Terms of Use, we and our advertisers, licensors or suppliers do not grant you any express or
                    implied rights to use any Protected IP, and all rights in any Protected IP not expressly granted by
                    us to you are reserved by us for our benefit and the benefit of our licensors.</p>
                <p>To the extent you may have any moral rights in any Content (including User Content), you confirm and
                    agree that: (a) you do not require that any personally identifying information be used in connection
                    with the Content, or any derivative works of or upgrades or updates to the Content; (b) you have no
                    objection to the publication, use, modification, deletion and exploitation of the Content by us or
                    our licensees, successors and assigns; (c) you forever waive and agree not to claim or assert any
                    entitlement to any and all moral rights of an author in any of the Content; and (d) you forever
                    release us, and our licensees, successors and assigns, from any claims that you could otherwise
                    assert against us by virtue of any such moral rights.</p>
                <p>We are the proprietor of Terrific.ie. All product or service names mentioned on the Website are the
                    trade marks of their respective owners, and other trade marks may be displayed on the Website from
                    time to time. Some material on the Website may contain other information containing intellectual
                    property of a third party. Nothing displayed on the Website should be construed as granting you any
                    licence or right of use of any logo, information or trade mark displayed on it, without the express
                    written permission of the relevant owner, except as expressly provided in the Terms of Use.</p>
                <p>You may reproduce material from the Website for your own personal, non-commercial reference and you
                    may draw the attention of others to material posted on the Website. Reproduction of material from
                    the Website for other purposes is permitted, provided that the source of the material is
                    acknowledged. In particular, you must not cache any of the contents for access by third parties, nor
                    mirror, scrape or frame any of the content of the Website, nor incorporate it into another website
                    without our express written permission. You may also permit your computer to make an electronically
                    stored, transient copy of the content on the Website for the purposes of viewing it while connected
                    to the internet only.</p>
                <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded
                    in any way, and you must not use any illustrations, photographs, or any graphics separately from any
                    accompanying text.</p>
                <p>Our status (and that of any partners, advertisers or third parties identified on the Website) as the
                    authors of material on the Website must always be acknowledged by you.</p>
                <p>You must not use any part of the Content on the Website for commercial purposes without obtaining a
                    licence to do so from us.</p>
                <p>If you print off, copy or download any part of the Website in breach of the User Agreement, your
                    right to use the Website will cease immediately and you must, at our option, return or destroy any
                    copies of the materials that you have made.</p>
                <h2>26. Disclaimer of liability</h2>
                <p>The Website and the Terrific.ie Service are provided on an 'as is' basis only. You may not rely on
                    any Content and/or other material on the Website and/or the Terrific.ie Service to make (or refrain
                    from making) any decision or to take (or refrain from taking) any action on which reliance should be
                    placed. We disclaim all liability (whether in contract, tort or otherwise) arising out of or in
                    connection with any reliance placed on such Content and/or materials by any user of the Website
                    and/or the Terrific.ie Service, or by anyone who may be informed of any of the Content. We accept no
                    responsibility for keeping the information in the Website and/or the Terrific.ie Service up to date
                    or complete or liability for any failure to do so.</p>
                <p>The Content and other material displayed on the Website is provided without any guarantees,
                    conditions or warranties as to its accuracy. To the extent permitted by law, we expressly
                    exclude:</p>
                <p>a. all conditions, warranties and other terms which might otherwise be implied by statute, common law
                    or the law of equity; and</p>
                <p>b. any liability for any loss of income or revenue, loss of business, loss of profits or contracts,
                    loss of anticipated savings, loss of data, loss of goodwill, wasted management or office time and
                    for any other loss or damage of any kind, any direct or any indirect or consequential loss or damage
                    incurred by any user in connection with the Website and/or Terrific.ie Service or in connection with
                    the use, inability to use, or results of the use of the Website and/or Terrific.ie Service, any
                    websites linked to it and any materials posted on it however arising and whether caused by tort
                    (including negligence), breach of contract or otherwise, even if foreseeable.</p>
                <p>Nothing in these Terms of Use shall exclude or limit our liability in relation to any damages arising
                    from death or personal injury caused by the negligence of Terrific.ie or for fraudulent
                    misrepresentation or misrepresentation as to a fundamental matter, or any other liability which
                    cannot be excluded or limited under applicable law.</p>
                <p>We shall not be liable for any failure to perform any of our obligations under the User Agreement
                    caused by matters beyond our reasonable control. Under no circumstances will we be liable in any way
                    for any Content, including, but not limited to, any errors or omissions in any Content, or any loss
                    or damage of any kind incurred as a result of the use of, access to, or denial of access to any
                    Content on the Website.</p>
                <p>Although we make all reasonable endeavours to ensure that the Website is free from viruses and
                    defects, we shall not be liable for any loss or damage caused by a distributed denial-of-service
                    attack, viruses, malicious or impairing computer programs or other technologically harmful material
                    that may infect your computer equipment, computer programs, data or other proprietary material due
                    to your use of the Website and/or Terrific.ie Service or to your downloading of any material posted
                    on it, or on any website linked to it.</p>
                <p>We do not guarantee the security of the online services or any systems connected with the use of the
                    Website (including the internet and your hardware and software) used in accessing the services, or
                    any information passed through such systems. We do not guarantee access to the Website or any
                    systems used in accessing our services will be continuous or virus or error free.</p>
                <p>Any material downloaded or otherwise obtained through the use of the Website is done at your own
                    discretion and risk and that you will be solely responsible for any damage to your computer system
                    or loss of data that results from the download of any such material. No advice or information,
                    whether oral or written, obtained by you from us or through or from the Website shall create any
                    warranty not expressly stated in the User Agreement.</p>
                <p>Where a discriminatory advertisement has been identified, the advertisement will be removed and no
                    refund will be paid for said advertisement.</p>
                <p>Where the conduct is repeated, it may give rise to a referral of said advertisement to the Irish
                    Human Rights and Equality Commission, the relevant regulatory body, who have the power to refer
                    complaints of discriminatory advertisement to the Workplace Relations Commission, where deemed
                    appropriate.</p>
                <p>A posting of a discriminatory advertisement on this site may constitute a repudiation of the contract
                    and may be a ground for treating the contract as coming to an end.</p>
                <h2>27. Indemnity</h2>
                <p>You shall indemnify and keep us, our holding company and subsidiaries (each as defined in the
                    Companies Act 2014) and our and their respective officers, directors, shareholders, employees,
                    agents, licensors and suppliers (each an "Indemnified Person") indemnified on demand from and
                    against all judgments, awards, penalties, settlements, fines, costs and expenses (including without
                    limitation, reasonable legal and accounting fees) suffered or incurred by an Indemnified Person and
                    arising out of or in connection with any of the following:</p>
                <p>(a) any breach of the User Agreement;</p>
                <p>(b) your access to or use of the Website, the Content or the Terrific.ie Service in a manner that
                    infringes or is alleged to infringe any applicable law or the rights (including, without limitation,
                    the privacy or intellectual property rights) of any other person.</p>
                <h2>28. Disclaimer of endorsement</h2>
                <p>Content on the Website referring to any products, process or service by trade name, trade mark,
                    manufacturer or otherwise, does not constitute or imply its endorsement, recommendation or
                    validation by us. We have not independently verified the accuracy of any description of any goods or
                    services advertised on the Website. The views, opinions and other Content posted by users on the
                    Website are not ours, shall not be attributed to us by you and shall not be used by you for any
                    advertising or product endorsement purposes unless we expressly authorise it.</p>
                <h2>29. Links to the Website</h2>
                <p>You may link to the Website, provided you do so in a way that is fair and legal and does not damage
                    our reputation or take advantage of it, but you must not establish a link in such a way as to
                    suggest any form of association, approval, sponsorship or endorsement on our part without our
                    express written permission or in order to directly compete with the Website and redirect traffic
                    from it. You must not establish a link from any website that is not owned by you.</p>
                <p>We reserve the right to withdraw linking permission without notice. The Website (in whole or in part)
                    must not be framed on any other website or in any other way altered or displayed in a different
                    manner to the manner which we display it.</p>
                <p>If you wish to make any use of material on the Website other than in the manner permitted by these
                    Terms of Use including as set out above please contact us.</p>
                <h2>30. Links from the Website</h2>
                <p>Where the Website contains links to other websites and resources provided by Banner Advertisers,
                    advertisers using the Website or other third parties, these links are provided for your information
                    only. We have no control over the contents of those websites or resources, and we accept no
                    responsibility for them or for any loss or damage that may arise from your use of them. If you link
                    to any such websites you leave the Website and do so entirely at your own risk.</p>
                <h2>31. Contact from Us</h2>
                <p>In the course of providing you services and in respect of your use of the Website, we may need to
                    communicate with you via email or the other details that you have submitted to us. You agree to
                    receive emails which are specific to your order and necessary for the normal functioning of the
                    Website, including emails which help inform users about functionality of the Website. You can read
                    more information about this in our Privacy Policy.</p>
                <h2>32. Privacy and Cookies Policies</h2>
                <p>Your privacy is important to us. Please review our Privacy Policy to see how we collect, protect,
                    process and use your personal data. The website also uses cookies and by using the Website you agree
                    to the placement of cookies. To learn more about the cookies we use and how to manage them please
                    review our Cookie Policy. Any external links to other websites are clearly identifiable as such, and
                    we are not responsible for the content or the privacy or cookie policies of these other
                    websites.</p>
                <h2>33. Governing law and jurisdiction</h2>
                <p>The User Agreement, and any non-contractual obligations arising out of or in connection with the User
                    Agreement are governed by, and shall be construed in accordance with, Irish law, and the courts of
                    Ireland shall have exclusive jurisdiction to hear, settle and determine any dispute which may arise
                    out of or in connection with the User Agreement save that retain the right to bring proceedings
                    against you in your country of residence or any other court of competent jurisdiction.</p>
                <h2>34. Waiver, Rights Cumulative</h2>
                <p>A failure to exercise or delay in exercising a right or remedy provided by the User Agreement or by
                    law does not constitute a waiver of the right or remedy or a waiver of other rights or remedies. No
                    single or partial exercise of a right or remedy provided by the User Agreement or by law prevents
                    further exercise of that right or remedy or the exercise of another right or remedy. The rights and
                    remedies contained in the User Agreement are cumulative and not exclusive of rights or remedies
                    provided by law.</p>
                <h2>35. Severability</h2>
                <p>If the whole or any part of a provision of the User Agreement is or becomes illegal, invalid or
                    unenforceable under the law of any jurisdiction, that shall not affect the legality, validity or
                    enforceability under the law of that jurisdiction of the remainder of the provision in question or
                    any other provision of the User Agreement and the legality, validity or enforceability under the law
                    of any other jurisdiction of that or any other provision of the User Agreement.</p>
                <h2>36. Assignment and entire agreement</h2>
                <p>We may assign or subcontract any or all of our rights and obligations under the User Agreement with
                    you to a third party at any time, at our discretion. You may not, without our prior written consent,
                    assign or dispose of any of your rights or obligations arising under the User Agreement.</p>
                <p>By agreeing to the User Agreement you are also agreeing to the transfer of, by way of novation, all
                    respective rights and obligations outlined in this document.
                </p>
                <p>The Terms of Use and the Policies contains the entire agreement and understanding between the parties
                    relating to the Website and the Terrific.ie Service, and supersedes any and all prior agreements,
                    arrangements, statements and understandings, except for any fraud or fraudulent representation by
                    either you or us. In the event of any inconsistency between any Policy and the Terms of Use, then
                    the Terms Use shall take priority.</p>
                <h2>37. Submissions</h2>
                <p>You acknowledge and agree that any questions, comments, suggestions, ideas, feedback and other
                    information about the Website and/or the Terrific.ie Service provided by you to us are
                    non-confidential and may be used by us at our discretion.</p>
            </div>

        </div>
        <main-footer></main-footer>
    </div>
</template>

<script>
    import MainHeader from '@/components/MainHeader.vue'
    import MainFooter from '@/components/MainFooter'
    import Breadcrumbs from '@/components/partials/Breadcrumbs'

    export default {
        name: "Terms",
        components: {
            MainHeader,
            MainFooter,
            Breadcrumbs
        },
        data: function () {
            return {
                title: "Terms &amp; Conditions of Use | Terrific.ie",
                description: "Terrific.ie details used cars for sale in Ireland including electric cars, 7 seaters and SUV’s.",
                keywords: "Cars for Sale, Used Cars, Cars, Second Hand Cars, Electric Cars, Audi, BMW, Ford, Toyota, 7 Seaters, Land Rover",
                robots: "all"
            }
        },
        serverPrefetch() {
            this.$ssrContext.title = this.title;
            this.$ssrContext.description = this.description;
            this.$ssrContext.keywords = this.keywords;
            this.$ssrContext.robots = this.robots;
        },
    }
</script>

<style scoped>
</style>
